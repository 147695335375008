import {
  faCheck,
  faExclamation,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormikContext } from "formik";
import Steps from "rc-steps";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import isEqual from "react-fast-compare";

import "rc-steps/assets/index.css";

import Form from "~/components/form";

function FormWizard({ steps, finishElement = null }) {
  const [accumulatedState, setAccumulatedState] = React.useState({});
  const [current, setCurrent] = React.useState(0);
  const [finished, setFinished] = React.useState(0);
  const [hasError, setHasError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const wrapStepAction = React.useCallback((promise) => {
    setIsLoading(true);
    promise
      .catch(() => {
        setHasError(true);
      })
      .finally(() => setIsLoading(false));
    return promise;
  }, []);
  const context = React.useMemo(
    () => ({ accumulatedState, wrapStepAction, setIsLoading }),
    [accumulatedState, wrapStepAction]
  );
  const currentStep = steps[current];
  const isFlashback = current < finished;

  const onChange = (value) => {
    if (isLoading) return;
    setHasError(false);
    setCurrent(value);
  };

  const prev = () => {
    if (isLoading) return;
    setHasError(false);
    setCurrent(current - 1);
  };

  const next = (shouldResetProgress) => {
    if (isLoading) return;
    setHasError(false);
    setCurrent(current + 1);
    setFinished(
      shouldResetProgress ? current + 1 : Math.max(finished, current + 1)
    );
  };

  const proceed = (data, shouldResetProgress) => {
    setAccumulatedState({ ...accumulatedState, ...data });
    next(shouldResetProgress);
  };

  const handeSubmit = (values) => {
    let shouldResetProgress = false;
    setHasError(false);
    if (isFlashback) {
      const untouched = isEqual(values, initialValues);
      if (untouched) {
        next(false);
        return undefined;
      }
      shouldResetProgress = true;
    }

    const boundProceed = (vals) => proceed(vals, shouldResetProgress);

    return (
      !currentStep.onSubmit
        ? boundProceed
        : (vals) =>
            wrapStepAction(currentStep.onSubmit(vals, accumulatedState)).then(
              boundProceed
            )
    )(values);
  };

  const syncSubmit = !currentStep.onSubmit;
  const validationSchema = getValidationSchema(currentStep, accumulatedState);
  const initialValues = getInitialValues(
    currentStep,
    accumulatedState,
    isFlashback
  );

  const items = getItems(steps, current, finished, isLoading, hasError);
  return (
    <wizardContext.Provider value={context}>
      <div className="form-wizard__root px-4">
        <div className="mt-5">
          <Steps
            current={current}
            onChange={onChange}
            labelPlacement="vertical"
            items={items}
            icons={{
              finish: <FontAwesomeIcon icon={faCheck} />,
              error: <FontAwesomeIcon icon={faExclamation} />,
            }}
          />
        </div>
        <Form
          key={current}
          initialValues={initialValues}
          syncSubmit={syncSubmit}
          validationSchema={validationSchema}
          onSubmit={handeSubmit}
        >
          <div className="mt-4">{currentStep.element}</div>
          <Row className="mt-4 gx-2 d-flex flex-row-reverse">
            <Footer
              current={current}
              prev={prev}
              total={steps.length}
              nextLabel={currentStep.nextLabel}
              finishElement={finishElement}
            />
          </Row>
        </Form>
      </div>
    </wizardContext.Provider>
  );
}

function Footer({ current, total, prev, nextLabel, finishElement }) {
  if (current + 1 === total) {
    return finishElement ? <Col xs="auto">{finishElement}</Col> : null;
  }
  return (
    <>
      <Col xs="auto">
        <NextButton label={nextLabel} />
      </Col>
      {current > 0 && (
        <Col xs="auto">
          <Button variant="outline-primary" onClick={() => prev()}>
            Back
          </Button>
        </Col>
      )}
    </>
  );
}

function NextButton({ label }) {
  const { isSubmitting, submitForm } = useFormikContext();
  return (
    <Button variant="primary" onClick={submitForm} disabled={isSubmitting}>
      {isSubmitting && (
        <FontAwesomeIcon className="fa-spin-pulse me-2" icon={faSpinner} />
      )}
      {label || "Next"}
    </Button>
  );
}

function getItems(steps, active, finished, isLoading, hasError) {
  return steps.map(({ name, icon, subTitle, description }, i) => ({
    title: name,
    subTitle,
    description,
    disabled: i > finished,
    ...getVisualState(i, active, finished, icon, isLoading, hasError),
  }));
}

function getInitialValues(currentStep, accumulatedState, isFlashback) {
  let { initialValues = {} } = currentStep;
  if (typeof initialValues === "function") {
    initialValues = initialValues(accumulatedState);
  }
  if (isFlashback) {
    initialValues = Object.fromEntries(
      Object.keys(initialValues).map((k) => [k, accumulatedState[k]])
    );
  }
  return initialValues;
}

function getValidationSchema(currentStep, accumulatedState) {
  const { validationSchema } = currentStep;
  if (typeof validationSchema === "function")
    return validationSchema(accumulatedState);
  return validationSchema;
}

function getVisualState(
  current,
  active,
  finished,
  stepIcon,
  isLoading,
  hasError
) {
  // current > finished
  let icon = stepIcon;
  let status;
  if (current === finished) {
    status = "process";
  } else if (current === active) {
    status = "finish";
  } else if (current < finished) {
    icon = undefined;
    status = "finish";
  }
  if (current === active) {
    if (hasError) {
      status = "error";
      icon = undefined;
    }
    if (isLoading) {
      icon = <FontAwesomeIcon className="fa-spin-pulse" icon={faSpinner} />;
    }
  }
  return { icon, status };
}

function useWizardContext() {
  return React.useContext(wizardContext);
}

const wizardContext = React.createContext();

export default FormWizard;
export { useWizardContext };
